import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Components/Home/Home";
import Add from "./Components/Add/Add";
import File from "./Components/File/File";
import SearchComponent from "./Components/Search/SearchComponent";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./themes/theme";

import "react-toastify/dist/ReactToastify.css";
import Tiemen from "./Components/Tiemen/Tiemen";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add" element={<Add />} />
          <Route path="/file/:barcode" element={<File />} />
          <Route path="/search" element={<SearchComponent />} />
          <Route path="/tiemen" element={<Tiemen />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <ToastContainer pauseOnFocusLoss={false} />
      </ThemeProvider>
    </div>
  );
}

export default App;

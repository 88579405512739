import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  getCountFromServer,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { Button } from "@mui/material";

const Tiemen = () => {
  const [totalAmountOfFiles, setTotalAmountOfFiles] = useState(0);
  const [totalAmountOfActiveFileLocation, setTotalAmountOfActiveFileLocation] = useState(0);

  const countFiles = async () => {
    const coll = collection(db, "files");
    const snapshot = await getCountFromServer(coll);
    console.log("count: ", snapshot.data().count);
    setTotalAmountOfFiles(snapshot.data().count);
  };
  const countActiveFileLocation = async () => {
    const coll = collection(db, "activeFileLocation");
    const snapshot = await getCountFromServer(coll);
    console.log("count: ", snapshot.data().count);
    setTotalAmountOfActiveFileLocation(snapshot.data().count);
  };
  let counter = 0;

  const migrateToActiveLocation = async () => {
    const migratieQuery = query(
      collection(db, "files"),
      
      orderBy("scannedOn", "asc")
    );
    const querySnapshot = await getDocs(migratieQuery);
    querySnapshot.forEach(async function (file) {
      // file.data() is never undefined for query file snapshots
      console.log(file.id, " => ", file.data());
      var activeLocationRef = doc(
        db,
        "activeFileLocation",
        file.data().barcode
      );
      try {
        await setDoc(activeLocationRef, {
          location: file.data().location,
        });
        counter++
        console.log(counter)
      } catch (e) {
        console.log("Error while adding to activefileLocation", e);
      }
    });
  };
  useEffect(() => {
    countFiles();
    countActiveFileLocation();
  }, []);
  return (
    <div>
      <div>{totalAmountOfFiles}</div>
      <div>{totalAmountOfActiveFileLocation}</div>
      <Button variant={"contained"} onClick={migrateToActiveLocation}>
        Migrate everything to ActiveLocation
      </Button>
    </div>
  );
};

export default Tiemen;

import React, { useState } from "react";
import { Box, TextField, Autocomplete, CircularProgress } from "@mui/material";

import { useTranslation } from "react-i18next";
import Papa from "papaparse";

const EditLocation = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      Papa.parse(
        "https://docs.google.com/spreadsheets/d/e/2PACX-1vQbgvASZQquujf8iVoycyiQQ1PvT2PTUvr1FjOC2BjIJ4UKxY0jrNNjQjksjRBspoXssd_ElYB3ads6/pub?gid=0&single=true&output=csv",
        {
          download: true,
          header: true,
          complete: (results) => {
            setOptions(results.data.map((item) => item.label.toUpperCase()));
          },
        }
      );
    })();
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        value={props.location || null}
        freeSolo={props.freeSolo}
        disableClearable
        id="locations-dropdown"
        options={options}
        isOptionEqualToValue={(option, value) => {
          return option === value;
        }}
        sx={{ width: 300, mt: 2, mb: 2 }}
        variant="standard"
        onChange={(event, newValue) => {
          if (newValue === null || newValue === "") {
            props.updateLocation("");
          } else {
            props.updateLocation(
              newValue.label
                ? newValue.label.toUpperCase()
                : newValue.toUpperCase()
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={props.location === "" || props.location === null}
            label={
              props.location === "" || props.location === null
                ? "No location selected"
                : t("words.location")
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default EditLocation;

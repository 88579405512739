import React, { useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import i18n from "../../utils/i18n";

import {
  Box,
  Switch,
  FormControlLabel,
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  List,
  ListItem,
  ListItemText,
  InputBase,
  styled,
  alpha,
  useTheme,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material/";
import {
  Description,
  LocationOn,
  Search as SearchIcon,
  Translate,
} from "@mui/icons-material/";
import { useTranslation } from "react-i18next";
import nodatayellow from "../../images/nodata_yellow.svg";
import startlooking from "../../images/startlooking.svg";
import CustomExport from "../General/Datagrid";
import { useNavigate } from "react-router-dom";
import LanguageSelector from "../General/LanguageSelector";
import EditLocation from "../Add/EditLocation";

let splitRegex = /[^a-zA-Z0-9]/;
const options = ["NL", "FR"];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),

  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Home = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [searchingFiles, setSearchingFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [location, SetLocation] = useState(localStorage.getItem("loc"));

  const handleSearchLocationSwitchChange = () => {
    handleClear();
    setSearchLocation(!searchLocation);
  };

  const handleLocationSearch = (passedLocation) => {
    setSearchInput(passedLocation);
    SetLocation(passedLocation);
    handleSearchChange(null, passedLocation);
  };

  const handleClear = () => {
    setSearching(false);
    setSearchingFiles([]);
    setSearchInput("");
    SetLocation();
  };

  const handleSearchChange = async (e, passedText) => {
    e?.preventDefault();
    setLoading(true);
    setSearchingFiles([]);
    let cleanedSearchInput = passedText
      ? passedText.split(splitRegex)[0]
      : searchInput.split(splitRegex)[0];

    if (cleanedSearchInput.length === 0) {
      setSearching(false);
      setLoading(false);
    } else {
      setSearching(true);
      setSearchInput(cleanedSearchInput);
      const barcodeQuery = query(
        collection(db, "files"),
        where("barcode", ">=", cleanedSearchInput),
        where("barcode", "<=", cleanedSearchInput + "~"),
        orderBy("barcode"),
        orderBy("scannedOn", "desc")
      );
      const locationQuery = query(
        collection(db, "activeFileLocation"),
        where("location", ">=", cleanedSearchInput),
        where("location", "<=", cleanedSearchInput + "~"),
        orderBy("location")
      );
      let querySnapshot;
      let newData;
      if (searchLocation) {
        querySnapshot = await getDocs(locationQuery);
        newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          barcode: doc.id,
          scannedOn: serverTimestamp(),
        }));
      } else {
        querySnapshot = await getDocs(barcodeQuery);
        newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
      }
      setSearchingFiles(newData);
      setLoading(false);
    }
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: "none", sm: "block" },
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/")}
          >
            AECETIA
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {searching && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleClear}
              >
                {t("words.clear").toUpperCase()}
              </Button>
            )}
            <form onSubmit={handleSearchChange}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <InputBase
                  placeholder={
                    searchLocation ? t("words.location") : t("words.filenumber")
                  }
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value.toUpperCase());
                  }}
                  sx={{
                    color: "inherit",
                    "& .MuiInputBase-input": {
                      padding: theme.spacing(1, 1, 1, 0),
                      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                      transition: theme.transitions.create("width"),
                      width: "100%",
                    },
                  }}
                />
              </Search>
            </form>
            <FormControlLabel
              sx={{ display: { xs: "none", sm: "block" } }}
              control={
                <Switch
                  checked={searchLocation}
                  onChange={handleSearchLocationSwitchChange}
                  inputProps={{ "aria-label": "controlled" }}
                  color="secondary"
                />
              }
              label={t("sentences.search for location")}
            />
            <IconButton
              sx={{ display: { xs: "block", sm: "none" }, color: "secondary" }}
              onClick={() => setSearchLocation(!searchLocation)}
              color="white"
            >
              {searchLocation ? (
                <LocationOn
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ) : (
                <Description
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              )}
            </IconButton>
          </Box>
          <LanguageSelector />
        </Toolbar>
      </AppBar>
      {searchLocation && (
        <Box>
          <EditLocation
            location={searchInput}
            updateLocation={handleLocationSearch}
            freeSolo={true}
          />
        </Box>
      )}
      {searching && loading && <CircularProgress />}
      <br />
      {!loading && searching && searchingFiles.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img width={"30%"} src={nodatayellow}></img>
          {t("sentences.no results")}
        </Box>
      )}
      {!loading && searching && searchingFiles.length > 0 && (
        <>
          <CustomExport
            files={searchingFiles}
            searchLocation={searchLocation}
          />
        </>
      )}

      <br />
      <Box>
        {!searching && (
          <Box>
            <Typography
              variant="h5"
              noWrap
              component="div"
              color="primary"
              sx={{ p: 2 }}
            >
              {t("sentences.start searching for files")}
            </Typography>
            <img width={"30%"} src={startlooking}></img>
          </Box>
        )}

        <Box>
          <Button variant="outlined" onClick={() => navigate("/add")}>
            {t("sentences.add files manually")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

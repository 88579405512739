import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { db } from "../../utils/firebase";
import {
  collection,
  serverTimestamp,
  writeBatch,
  doc,
  Timestamp,
} from "firebase/firestore";
import { toast } from "react-toastify";
import CSVReader from "react-csv-reader";
import { FileUpload } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
let splitRegex = /[^a-zA-Z0-9]/;

const AddFromFile = () => {
  const { t } = useTranslation();

  const [csvData, setCsvData] = useState([]);

  const handleForce = (data, fileInfo) => {
    console.log(data, fileInfo);
    setCsvData(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => {
      switch (header.toLowerCase()) {
        case "dossiernummer":
        case "numéro de dossier":
          return "barcode";
        case "lieu":
        case "locatie":
          return "location";
        case "date":
        case "datum":
          return "scannedOn";
        default:
          return header;
      }
    },
  };

  const batchDataToFirestore = async (data) => {
    try {
      // Get a new write batch
      const batch = writeBatch(db);
      try {
        //Loop over CSV data en set batches
        data.map((row) => {
          //Converte date to Firestore Timestamp
          let fsTimestamp = serverTimestamp();
          if (row.scannedOn && row.scannedOn !== null) {
            let dateParts = row.scannedOn.split(",")[0].split("/");
            let timeParts =
              row.scannedOn.split(",")[1] !== undefined
                ? row.scannedOn
                    .split(",")[1]
                    .split(":")
                    .map((part) => parseInt(part))
                : new Date()
                    .toLocaleTimeString()
                    .split(":")
                    .map((part) => parseInt(part));

            // Check if seconds part is available
            let seconds = timeParts.length > 2 ? timeParts[2] : 0;
            let dateObject = new Date(
              +dateParts[2],
              dateParts[1] - 1,
              +dateParts[0],
              timeParts[0],
              timeParts[1],
              seconds
            );
            fsTimestamp = Timestamp.fromDate(dateObject);
          }
          const newRef = doc(collection(db, "files"));
          batch.set(newRef, {
            barcode: row.barcode.split(splitRegex)[0].toUpperCase(),
            location: row.location.toUpperCase(),
            scannedOn: fsTimestamp,
          });
          const newActiveFileLocationRef = doc(
            db,
            "activeFileLocation",
            row.barcode.split(splitRegex)[0].toUpperCase()
          );
          batch.set(newActiveFileLocationRef, {
            location: row.location.toUpperCase(),
          });
        });
      } catch (e) {
        console.log(e);
        toast.error(
          "Something has gone wrong while processing the file. Please check your file. If all else fails, contact the webmaster."
        );
      }
      try {
        await batch.commit();
        toast.success(`${data.length} files were added 🎉`);
        setCsvData([]);
      } catch (e) {
        console.log(e);
        toast.error(
          "Something has gone wrong while adding the files. You won't know what happened. Send the file to your webmaster and let him/her struggle."
        );
      }
      // Commit the batch
    } catch (e) {
      console.log(e);
      toast.error(
        "Something has gone wrong. Send the file you were trying to upload to the webmaster after you've checked it for mistakes yourself"
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {csvData.length < 1 && (
        <Button variant="outlined">
          <CSVReader
            cssClass="react-csv-input"
            inputStyle={{ display: "none" }}
            label={
              <Box variant="contained" sx={{ display: "flex" }}>
                <FileUpload />
                {t("sentences.import file")}
              </Box>
            }
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          />
        </Button>
      )}
      {csvData.length > 0 && (
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("words.filenumber")}</TableCell>
                  <TableCell>{t("words.location")}</TableCell>
                  <TableCell>{t("words.date")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.barcode}</TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.scannedOn}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <Button
            variant="contained"
            onClick={() => batchDataToFirestore(csvData)}
          >
            Add these files
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AddFromFile;

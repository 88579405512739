import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#3f6d74",
      dark: "#3f6d74",
      light: "#599ba5",
    },
    secondary:{
        main: "#EEE3AB"
    },
    white: {main:"#FFFFFF"}
//     grey:{
//       light: "#F6F6F7",
//       dark: "#EBEBEE"
//     }
//   },
//   typography:{
//     fontFamily: "'Poppins', 'SF Pro Display', 'sans-serif'"
//   },
//   shape:{
//     borderRadius: 10
//   },
//    fabStyle : {
//     position: "fixed !important",
//     bottom: 16,
//     right: 16,
  },
 
});

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, orderBy, where, doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { toast } from "react-toastify";
import EnhancedTable from "../General/EnhancedTable";
import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import CustomExport from "../General/Datagrid";

const File = () => {
  let { barcode } = useParams();
  const theme = useTheme();

  const [files, setFiles] = useState();
  const [activeLocation, setActiveLocation] = useState();
  const q = query(
    collection(db, "files"),
    where("barcode", "==", barcode),
    orderBy("scannedOn", "desc")
  );

  const fetchFiles = async () => {
    try {
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFiles(data);
    } catch (e) {
      toast.error("Something went wrong");
      console.error(e);
    } finally {
    }
  };
  const fetchActiveLocation = async () => {
    try {
      const docRef = doc(db, "activeFileLocation", barcode);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setActiveLocation(docSnap.data().location);
      } else {
        // docSnap.data() will be undefined in this case
        setActiveLocation("No activeFileLocation was found");
      }
    } catch (e) {
      toast.error("Something went wrong");
      console.error(e);
    }
  };

  useEffect(() => {
    fetchFiles();
    fetchActiveLocation();
  }, []);

  return (
    <Box>
      <Typography
        variant="h3"
        noWrap
        component="div"
        color="primary"
        sx={{ p: 2 }}
      >
        {barcode}
      </Typography>
      {activeLocation}
      {files && files.length > 0 && (
        // <EnhancedTable files={files} visibleSearchIcon={false} />
        <CustomExport files={files} visibleSearchIcon={false} />
      )}
    </Box>
  );
};

export default File;

import React, { useState, useEffect } from "react";
import {
    collection,
    getDocs,
    query,
    orderBy,
    where,
    limit,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material/";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const SearchComponent = () => {
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [files, setFiles] = useState([]);
  const {state} = useLocation();
  
  
  if(state.searchInputPassed.length > 0){
    // setSearchInput(searchInputPassed)
    
    console.log(state.searchInputPassed)
  }


    const handleSearchChange = async (e) => {
        e.preventDefault();
        if (searchInput.length === 0) {
          navigate('/')
        } else {
          const q = query(
            collection(db, "files"),
            where("barcode", ">=", searchInput),
            where("barcode", "<=", searchInput + "~")
          );
          const querySnapshot = await getDocs(q);
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setFiles(newData);
    
        }
      };

  return (
    <Box sx={{ width: 300, justifyContent: "center" }}>
      <br />
      <form onSubmit={handleSearchChange}>
        <TextField
          label="Dossiernummer"
          onChange={(e) => setSearchInput(e.target.value)}
          InputProps={{
            type: "search",
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {files.length > 0 &&
        files.map((file) => {
          return <div key={file.id}>{file.barcode}</div>;
        })}
    </Box>
  );
};

export default SearchComponent;

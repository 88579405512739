import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Delete, Search as SearchIcon } from "@mui/icons-material/";
import { deleteDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../utils/firebase";

const csvOptions = {
  delimiter: ";",
  utf8WithBom: true,
  fields: ["barcode", "location", "scannedOn"],
};

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
}
function NavigateButton(params) {
  return (
    <Button
      variant="outlined"
      onClick={() => window.open(`/file/${params.row.barcode}`, "_blank")}
    >
      <SearchIcon />
    </Button>
  );
}

const handleDeleteSingleItem = async (row) => {
  try {
    await deleteDoc(doc(db, "files", row.id));
    toast.success("item deleted");
  } catch (e) {
    toast.error("Something went wrong while deleting this row");
    console.log(e);
  }
};

function DeleteButton(params) {
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={() => handleDeleteSingleItem(params)}
    >
      <Delete />
    </Button>
  );
}

export default function CustomExport(props) {
  const {
    files,
    searchLocation,
    visibleSearchIcon = true,
    visibleDeleteIcon = true,
  } = props;
  const { t } = useTranslation();

  const columns = [
    {
      field: "visibleSearchIcon",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: NavigateButton,
    },
    { field: "barcode", headerName: t("words.filenumber"), flex: 1 },
    { field: "location", headerName: t("words.location"), flex: 1 },
    {
      field: "scannedOn",
      headerName: t("words.date"),
      flex: 1,
      valueGetter: (params) => {
        return params.row.scannedOn.toDate().toLocaleString();
      },
    },
    {
      field: "visibleDeleteIcon",
      headerName: "",
      flex: 0.5,
      renderCell: DeleteButton,
    },
  ];
  const searchLocationColumns = [
    {
      field: "visibleSearchIcon",
      headerName: "",
      sortable: false,
      flex: 0.5,
      renderCell: NavigateButton,
    },
    { field: "barcode", headerName: t("words.filenumber"), flex: 1 },
    { field: "location", headerName: t("words.location"), flex: 1 },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        sx={{
          outline: "none",
          root: {
            "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          },
          ".css-ou9q14-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-ou9q14-MuiDataGrid-root .MuiDataGrid-cell:focus":
            {
              outline: "none",
            },
        }}
        rows={files}
        columns={searchLocation ? searchLocationColumns : columns}
        // loading={loading}
        slots={{ toolbar: CustomToolbar }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        disableVirtualization
        GridColDef={false}
        columnVisibilityModel={{
          visibleSearchIcon: visibleSearchIcon,
          visibleDeleteIcon: visibleDeleteIcon,
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
}

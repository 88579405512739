import React, { useState } from "react";

import { MenuItem, Menu, List, ListItem, ListItemText } from "@mui/material/";
import {
    Translate,
  } from "@mui/icons-material/";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const options = ["NL", "FR"];
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(options.indexOf(i18n.language.toUpperCase()));

  const open = Boolean(anchorEl);

  
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index, option) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    i18n.changeLanguage(option.toLowerCase());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List component="nav" aria-label="Language settings">
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="language-menu"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <Translate sx={{ px: "8px" }} />
          <ListItemText
            primary={selectedIndex > 0 ? options[selectedIndex] : "NL"}
          />
        </ListItem>
      </List>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default LanguageSelector;

import React, { useState } from "react";
import {
  AppBar,
  Box,
  Icon,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { db } from "../../utils/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

import { toast } from "react-toastify";
import AddFromFile from "./AddFromFile";
import LanguageSelector from "../General/LanguageSelector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditLocation from "./EditLocation";
let splitRegex = /[^a-zA-Z0-9]/;

const Add = () => {
  const [newFileCode, setNewFileCode] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [location, SetLocation] = useState(localStorage.getItem("loc"));

  const updateLocation = (passedLocation) => {
    console.info(passedLocation);
    if (passedLocation === undefined || passedLocation === "") {
      localStorage.removeItem("loc");
      SetLocation("");
    } else {
      window.localStorage.setItem("loc", passedLocation);
      SetLocation(passedLocation);
    }
  };

  const handleAddFileFromInput = async (e) => {
    e.preventDefault();
    //HANDLE LOCATION CHANGE
    if (newFileCode.split(splitRegex)[0] === "NEWLOCATION") {
      try {
        updateLocation(newFileCode.split(splitRegex)[1].toUpperCase());
      } catch (e) {
        toast.warning(e);
      } finally {
        toast.success(
          `Set location to ${newFileCode.split(splitRegex)[1].toUpperCase()}`
        );
        setNewFileCode("");
      }
    }
    //ADD(OR REMOVE) FILE TO DATABASE
    else {
      setNewFileCode("");
      try {
        await fetch(
          "https://europe-west1-ims-aecetia.cloudfunctions.net/barcode",
          {
            method: "post",
            mode: "no-cors",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              barcode: newFileCode.split(splitRegex)[0],
              location: location,
              scannedOn: Date.now(),
            }),
          }
        );
        toast.success(`Added ${newFileCode.split(splitRegex)[0]}`);
      } catch (e) {
        toast.error(
          "Something has gone wrong while sending the data to the middleware"
        );
        console.log(e);
      }
      // if (location === "DELETE") {
      //   let docSnap;
      //   try {
      //     const deleteQuery = query(
      //       collection(db, "files"),
      //       where("barcode", "==", newFileCode.split(splitRegex)[0])
      //     );

      //     docSnap = await getDocs(deleteQuery);
      //     console.log(docSnap);
      //     docSnap.forEach((doc) => {
      //       deleteDoc(doc.ref);
      //     });
      //   } catch (e) {
      //     toast.error(e);
      //     console.log(e);
      //   } finally {
      //     toast.success(
      //       `${docSnap.size} movement(s) related to ${
      //         newFileCode.split(splitRegex)[0]
      //       } deleted`
      //     );
      //     setNewFileCode("");
      //   }
      // } else {
      //   if (location === null || location === "") {
      //     toast.error("No location selected");
      //   } else {
      //     try {
      //       await addDoc(collection(db, "files"), {
      //         barcode: newFileCode.split(splitRegex)[0],
      //         location: location,
      //         scannedOn: serverTimestamp(),
      //       });
      //     } catch (e) {
      //       console.error("Error adding document: ", e);
      //     } finally {
      //       toast.success(`Added ${newFileCode.split(splitRegex)[0]}`);
      //       setNewFileCode("");
      //     }
      //   }
      // }
    }
  };

  return (
    <Box>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: "none", sm: "block" },
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => navigate("/")}
          >
            AECETIA
          </Typography>
          <LanguageSelector />
        </Toolbar>
      </AppBar>
      <Typography
        variant="h5"
        noWrap
        component="div"
        color="primary"
        sx={{ p: 2 }}
      >
        {t("sentences.add files by file")}
      </Typography>
      <Box>
        <Box>
          <EditLocation location={location} updateLocation={updateLocation} />
          <Box></Box>
          <form onSubmit={handleAddFileFromInput}>
            <TextField
              label={t("words.filenumber")}
              onChange={(e) => setNewFileCode(e.target.value.toUpperCase())}
              InputProps={{
                type: "search",
              }}
              value={newFileCode}
              sx={{ mt: 1 }}
            />
          </form>
        </Box>

        <br />
      </Box>
      <hr />
      <Box>
        <Typography
          variant="h5"
          noWrap
          component="div"
          color="primary"
          sx={{ p: 2 }}
        >
          {t("sentences.add files from csv")}
        </Typography>
      </Box>

      <AddFromFile />
    </Box>
  );
};

export default Add;
